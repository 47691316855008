/** @format */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans&display=swap");
@import url("//fonts.googleapis.com/earlyaccess/droidarabickufi.css");

body {
	margin: 0;
	padding: 0px;
	box-sizing: border-box;
	/* background: #00458a !important; */
	height: 100%;
	font-family: Montserrat, Helvetica, Arial, sans-serif;
}

:root {
	--light-blue: #6ec1e4;
	--grey-shade-1: #54595f;
	--text-color: #7a7a7a;
	--grey-background: #fafafa;
	--text-color2: #c00449;
	--global-color-accent: #d9f3dc;
	--background-1: #15162c;
	--background-0: #a6a8d5;
	--backgroundMain: #f6f6fb;
	--global-color-12398c4: #23a455;
	--goldColor: #e9c79e;
	--goldColor2: #f4e3ce;
	--goldColorMain: #e0af74;
	--goldColorMain2: #d86804;
	--mainTransition: all 0.3s ease-in-out;
}

.DarkbackgroundForSidebar {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 79px;
	left: 0;
	position: fixed;
	z-index: 1000;
	padding: 1000px;
}

.DarkbackgroundForSidebar2 {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 80px;
	left: 0;
	position: fixed;
	z-index: 1000;
	padding: 1200px;
}

.main-footer {
	color: black;
	padding-top: 1rem;
	position: relative;
	bottom: 0;
	width: 100%;
}

.spinning-loader {
	margin-bottom: 550px;
	margin-top: 150px;
	margin-left: 48%;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: darkgrey;
	font-size: 12.5px;
}
